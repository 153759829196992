<template>
  <div class="add-commodity-bg">
    <div class="add-commodity-con">
      <h2 class="page-title">
        <span class="goToBack" @click="goBack">
          <i class="el-icon-back"></i>
          <span>返回</span>
        </span>
      </h2>
      <b-container class="add-commodity-items">
        <div class="add-commodity-items-inner">
          <el-form :model="form" :rules="rules" ref="form"  label-width="110px">
            <el-form-item label="商品名称：" prop="tradeName">
              <el-input v-model="form.tradeName" placeholder="请输入商品名称"></el-input>
            </el-form-item>
            <el-form-item label="所属板块：" prop="plate">
              <el-select v-model="form.plate" placeholder="请选择所属板块">
                <el-option :label="item" :value="item" v-for="(item, index) in form.goodsType" :key="index"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="商品品牌：" prop="brand">
              <el-select v-model="form.brand" placeholder="请选择商品品牌">
                <el-option :label="item" :value="item" v-for="(item, index) in form.goodsBrand" :key="index"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item  label="风格分类：" required>
              <div class="inline">
                <el-form-item prop="style">
                  <el-select v-model="form.style" placeholder="请选择一级风格">
                    <el-option :label="item" :value="item" v-for="(item, index) in form.goodsStyle" :key="index"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item prop="subStyle">
                  <el-select v-model="form.subStyle" placeholder="请选择二级风格" class="sub-style">
                    <el-option :label="item" :value="item" v-for="(item, index) in form.goodsSubStyle" :key="index"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-form-item>
            <el-form-item  label="商品分类：" required>
              <div class="inline">
                <el-form-item prop="classify">
                  <el-select v-model="form.classify" placeholder="请选择一级分类">
                    <el-option :label="item" :value="item" v-for="(item, index) in form.goodsClassify" :key="index"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item prop="subClassify">
                  <el-select v-model="form.subClassify" placeholder="请选择二级分类" class="sub-classify">
                    <el-option label="区域一" value="shanghai"></el-option>
                    <el-option label="区域二" value="beijing"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item prop="childClassify">
                  <el-select v-model="form.childClassify" placeholder="请选择三级分类" class="child-classify">
                    <el-option label="区域一" value="shanghai"></el-option>
                    <el-option label="区域二" value="beijing"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-form-item>
            <div class="inline-inp">
              <el-form-item label="超级会员折扣：">
                <el-input v-model="form.operateDiscount" placeholder="请输入商品折扣(0~1)"></el-input>
              </el-form-item>
              <el-form-item label="高级会员折扣：">
                <el-input v-model="form.memberDiscount" placeholder="请输入商品折扣(0~1)"></el-input>
              </el-form-item>
              <el-form-item label="普通会员折扣：">
                <el-input v-model="form.distributionDiscount" placeholder="请输入商品折扣(0~1)"></el-input>
              </el-form-item>
            </div>
            <el-form-item label="服务介绍：">
              <el-input v-model="form.serviceIntroduction" placeholder="付款后15-35天内发货，物流周期3-7天。"></el-input>
            </el-form-item>
            <el-form-item label="排序：">
              <el-input v-model="form.sort" placeholder="0"></el-input>
            </el-form-item>
            <!-- 商品属性开始 -->
            <el-form-item label="商品属性：" required>
              <div class="attr-group">
                <!-- 材质/面料开始 -->
                <div class="material">
                  <el-form-item>
                    <el-input v-model="form.materialAttr[0]" class="material-name"></el-input>：
                    <el-input v-model="form.materialAttr[1]"></el-input>
                    <el-button class="add-btn" @click="addMaterialItem">增加</el-button>
                  </el-form-item>
                  <div class="material-items" v-if="form.materialItems.length > 0">
                    <el-form-item v-for="(item, index) in form.materialItems" :prop="'materialItems.' + index + '.value'" :rules="{required: true, message: '材质/面料属性不能为空', trigger: 'blur'}" :key="index">
                      <el-checkbox v-model="item.checked" class="check-box" @change="materialItemCheckedChange(item)"></el-checkbox>
                      <el-input v-model="item.value"></el-input>
                      <span class="del" @click="materialItemDel(item)">删除</span>
                    </el-form-item>
                  </div>
                </div>
                <!-- 材质/面料结束 -->
                <!-- 规格/尺寸开始 -->
                <div class="material">
                  <el-form-item>
                    <el-input v-model="form.specification[0]" class="material-name"></el-input>：
                    <el-input v-model="form.specification[1]"></el-input>
                    <el-button class="add-btn" @click="addSpecificationItem">增加</el-button>
                  </el-form-item>
                  <div class="material-items clearfix">
                    <el-form-item v-for="(item, index) in form.specificationItems" :prop="'specificationItems.' + index + '.value'" :rules="{required: true, message: '规格/尺寸属性不能为空', trigger: 'blur'}" :key="index">
                      <el-checkbox v-model="item.checked" class="check-box" @change="specificationItemCheckedChange(item)"></el-checkbox>
                      <el-input v-model="item.value"></el-input>
                      <span class="del" @click="specificationItemDel(item)">删除</span>
                    </el-form-item>
                  </div>
                </div>
                <!-- 规格/尺寸结束 -->
                <!-- 颜色开始 -->
                <div class="colors" v-if="form.colorsItems.length > 0">
                  <el-form-item label="颜色：" label-width="54px">
                    <div class="colors-items-wrap">
                      <div class="colors-item" :style="item.isColor ? `background-color: ${item.color};` : `background-image: url('${item.color}')`" v-for="(item, index) in form.colorsItems" :key="index" @click="selectColors(item)">
                        <i :class="item.checked ? 'el-icon-check checked' : 'el-icon-check'"></i>
                      </div>
                    </div>
                  </el-form-item>
                </div>
                <!-- 颜色结束 -->
              </div>
            </el-form-item>
            <!-- 商品属性结束 -->
            <!-- 商品属性列表开始 -->
            <div class="attr-list" v-if="form.skuArr.length">
              <table>
                <thead>
                  <tr>
                    <th v-if="form.skuArr[0].materialName">材质/面料</th>
                    <th v-if="form.skuArr[0].specificationName">规格/尺寸</th>
                    <th v-if="form.skuArr[0].color" class="color">颜色</th>
                    <th class="selling-price">销售价格</th>
                    <th class="stock">库存</th>
                    <th class="commodity-model">商品型号</th>
                    <th class="commodity-picture">商品图片</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in form.skuArr" :key="index">
                    <td v-if="item.materialName">
                      {{item.materialName}}
                    </td>
                    <td v-if="item.specificationName">
                      {{item.specificationName}}
                    </td>
                    <td v-if="item.color">
                      {{item.color.split("%&%")[1]}}
                    </td>
                    <td>
                      <el-input v-model="item.salePrice"></el-input>
                    </td>
                    <td>
                      <el-input v-model="item.stock"></el-input>
                    </td>
                    <td>
                      <el-input v-model="item.goodsMarque"></el-input>
                    </td>
                    <td>
                      <el-upload
                        :action= "item.upload.url"
                        list-type="picture-card"
                        :on-success="picUploadSuccess(item)"
                        :on-preview="picCardPreview"
                        :on-remove="picRemove(item)">
                        <i class="el-icon-plus"></i>
                      </el-upload>
                      <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="">
                      </el-dialog>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- 商品属性列表结束 -->
            <!-- 商品视频开始 -->
            <div class="commodity-video">
              <el-form-item label="视频上传：" >
                <!-- action必选参数, 上传的地址 -->
                <el-upload class="video-upload" :action="uploadUrl" :show-file-list="false" :on-success="videoUploadSuccess" :before-upload="beforeUploadVideo" :on-progress="uploadVideoProcess">
                  <video v-if="form.video !='' && form.videoFlag == false" :src="form.video" controls="controls">您的浏览器不支持视频播放</video>
                  <i v-else-if="form.video =='' && form.videoFlag == false" class="el-icon-plus"></i>
                  <el-progress v-if="form.videoFlag == true" type="circle" :percentage="videoUploadPercent"></el-progress>
                </el-upload>
                <p class="text">请保证视频格式正确，且不超过10M</p>
              </el-form-item>
              <el-form-item label="视频封面：">
                <el-upload class="video-cover" :action="uploadUrl" :show-file-list="false" :before-upload="beforVideoCoverUpload" :on-success="videoCoverUploadSuccess">
                  <img v-if="form.coverImageUrl" :src="form.coverImageUrl" class="img-cover">
                  <i v-else class="el-icon-plus"></i>
                </el-upload>
                <p class="text">上传视频必须上传视频封面图</p>
              </el-form-item>
            </div>
            
            <!-- 商品视频结束 -->
            <!-- 富文本编辑器开始 -->
            <el-form-item label="商品详情：">
              <quill-editor ref="text" v-model="content" class="quill-editor" :options="editorOption" />
              <div class="btn-wrap">
                <el-button @click="submit('form')" class="submit">提交</el-button>
                <el-button @click="cancel">取消</el-button>
              </div>
            </el-form-item>
            <!-- 富文本编辑器结束 -->
          </el-form>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
  import dump from "@/util/dump";
  import Url from "@/api/config";
  import { quillEditor } from 'vue-quill-editor';
  import quillConfig from "@/util/quillConfig";
  import 'quill/dist/quill.core.css';
  import 'quill/dist/quill.snow.css';
  import 'quill/dist/quill.bubble.css';
  export default {
    components: {
      quillEditor
    },
    data (){
      return {
        form: {
          tradeName: "",
          plate: "",
          // goodsType: ["臻选家具", "严选软装", "优选品牌", "精选套餐"],
          goodsType: ["臻选家具", "严选软装", "优选品牌"],
          brand: "",
          goodsBrand: ["臻选","梵恰","众杰家具"],
          style: "",
          goodsStyle: ["极简","轻奢","中式"],
          subStyle: "",
          goodsSubStyle: ["二级风格1","二级风格2"],
          classify: "",
          goodsClassify: ["家具馆", "家纺馆", "家饰馆", "灯饰馆"],
          subClassify: "",
          childClassify: "",
          operateDiscount: "",
          memberDiscount: "",
          distributionDiscount: "",
          serviceIntroduction: "",
          sort: 0,
          materialAttr: ['材质/面料',""],
          specification: ["规格/尺寸", ""],
          materialItems: [],
          specificationItems: [],
          colorsItems: [
            {
              checked: false,
              isColor: true,
              color: '#f00',
              colorName: "红色"
            },
            {
              checked: false,
              isColor: true,
              color: '#fba4af',
              colorName: "粉色"
            },
            {
              checked: false,
              isColor: true,
              color: '#f469b1',
              colorName: "紫红"
            },
            {
              checked: false,
              isColor: true,
              color: '#8a2be2',
              colorName: "深紫"
            },
            {
              checked: false,
              isColor: true,
              color: '#b66afd',
              colorName: "浅紫"
            },
            {
              checked: false,
              isColor: true,
              color: '#5b4adc',
              colorName: "蓝紫"
            },
            {
              checked: false,
              isColor: true,
              color: '#000066',
              colorName: "深蓝"
            },
            {
              checked: false,
              isColor: true,
              color: '#00f',
              colorName: "蓝色"
            },
            {
              checked: false,
              isColor: true,
              color: '#87cefa',
              colorName: "天蓝"
            },
            {
              checked: false,
              isColor: true,
              color: '#20b2aa',
              colorName: "蓝绿"
            },
            {
              checked: false,
              isColor: true,
              color: '#228b22',
              colorName: "深绿"
            },
            {
              checked: false,
              isColor: true,
              color: '#44d244',
              colorName: "浅绿"
            },
            {
              checked: false,
              isColor: true,
              color: '#9acd32',
              colorName: "黄绿"
            },
            {
              checked: false,
              isColor: true,
              color: '#ffff00',
              colorName: "黄色"
            },
            {
              checked: false,
              isColor: true,
              color: '#fdf5e6',
              colorName: "米色"
            },
            {
              checked: false,
              isColor: true,
              color: '#9c661f',
              colorName: "褐色"
            },
            {
              checked: false,
              isColor: true,
              color: '#ff8c00',
              colorName: "橙色"
            },
            {
              checked: false,
              isColor: true,
              color: '#e9c69a',
              colorName: "木色"
            },
            {
              checked: false,
              isColor: true,
              color: '#4e3632',
              colorName: "深棕"
            },
            {
              checked: false,
              isColor: true,
              color: '#000000',
              colorName: "黑色"
            },
            {
              checked: false,
              isColor: true,
              color: '#505050',
              colorName: "深灰"
            },
            {
              checked: false,
              isColor: true,
              color: '#a9a9a9',
              colorName: "浅灰"
            },
            {
              checked: false,
              isColor: true,
              color: '#ffffff',
              colorName: "白色"
            },
            {
              checked: false,
              isColor: false,
              color: require('@/../public/icon/userCenter/addCommodity/colour.png'),
              colorName: "花色"
            },
            {
              checked: false,
              isColor: false,
              color: require('@/../public/icon/userCenter/addCommodity/gold.png'),
              colorName: "金色"
            },
            {
              checked: false,
              isColor: false,
              color: require('@/../public/icon/userCenter/addCommodity/silver.png'),
              colorName: "银色"
            },
            {
              checked: false,
              isColor: false,
              color: require('@/../public/icon/userCenter/addCommodity/transparent.png'),
              colorName: "透明"
            }
          ],
          checkedMaterials: [],
          checkedSpecifications: [],
          checkedColors: [],
          skuArr: [],
          video: "",
          videoFlag: false,
          coverImageUrl: "",
          videoCoverImageUrl: ""
        },
        checked: false,
        dialogImageUrl: '',
        dialogVisible: false,
        content: '',
        editorOption: quillConfig,
        uploadUrl: "",
        token: null,
        // 表单验证
        rules: {
          tradeName: [
            { required: true, message: '请输入商品名称', trigger: 'blur' },
          ],
          plate: [
            { required: true, message: '请选择所属板块', trigger: 'change' }
          ],
          brand: [
            { required: true, message: '请选择商品品牌', trigger: 'change' }
          ],
          style: [
            { required: true, message: '请选择一级风格', trigger: 'change' }
          ],
          subStyle: [
            { required: true, message: '请选择二级风格', trigger: 'change' }
          ],
          classify: [
            { required: true, message: '请选择一级分类', trigger: 'change' }
          ],
          subClassify: [
            { required: true, message: '请选择二级分类', trigger: 'change' }
          ],
          childClassify: [
            { required: true, message: '请选择三级分类', trigger: 'change' }
          ]
        }
      }
    },
    // mounted() {
    //   quillConfig.initButton();
    // },
    created() {
      this.init();
    },
    methods: {
      init(){
        this.token = localStorage.getItem("token");
        this.uploadUrl = `${Url}/ossupload/uploadImg.do`;
        this.form.colorsItems.forEach(item => {
          item.value = item.color + '%&%' + item.colorName;
        })
      },
      // 返回商品管理列表页
      goBack(){
        dump.link({
          type: 1,
          link: "/usercenter/commodityList"
        });
      },
      // 删除商品sku图片
      picRemove(obj) {
        return (file) => {
          let removePic = file.response.data;
          let picArr = obj.upload.pic.split(",");
          
          let index = picArr.indexOf(removePic);
          if(index !== -1){
            picArr.splice(index, 1);
          }
          obj.upload.pic = picArr.join(",");
        }
      },
      // 预览商品sku图片
      picCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      // 商品sku图片上传成功回调
      picUploadSuccess(obj){
        return (res) => {
          if(obj.upload.pic){
            obj.upload.pic += "," + res.data;
          }else{
            obj.upload.pic = res.data;
          }
        }
      },
      
      // 商品视频上传前的处理函数
      beforeUploadVideo(file){
        const isLt10M = file.size / 1024 / 1024  < 10;
        if(['video/mp4', 'video/ogg', 'video/flv','video/avi','video/wmv','video/rmvb'].indexOf(file.type) == -1){
          this.$message.error('请上传正确的视频格式');
          return false;
        }
        if(!isLt10M) {
          this.$message.error('上传视频大小不能超过10MB哦!');
          return false;
        }
      },
      
      // 视频上传进度条
      uploadVideoProcess(event, file){
        this.form.videoFlag = true;
        this.videoUploadPercent = Number(file.percentage.toFixed(0));
      },
      
      // 商品视频上传成功的回调
      videoUploadSuccess(res){
        console.log(res)
        this.form.videoFlag = false;
        this.videoUploadPercent = 0;
        if(res.status == 200){
          this.form.videoUploadId = res.data.uploadId;
          this.form.video = res.data.uploadUrl;
        }else{
          this.$message.error('视频上传失败，请重新上传！');
        }
      },
      
      // videoUploadPercent(){
        
      // },
      
      // 视频封面图上传前
      beforVideoCoverUpload(file){
        const isJPGOrPNG = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;
        
        if (!isJPGOrPNG) {
          return this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!');
        }
        if (!isLt2M) {
          return this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isJPGOrPNG && isLt2M;
      },
      
      // 视频封面上传成功回调
      videoCoverUploadSuccess(res, file){
        this.form.videoCoverImageUrl = res.data;
        this.form.coverImageUrl = URL.createObjectURL(file.raw);
      },
      
      // 添加材质面料属性项 
      addMaterialItem(){
        // 判断属性是否存在
        if(!this.form.materialAttr[1]) return this.$message.error(`请输入${this.form.materialAttr[0]}属性`);
        
        // 判断是否添加重复属性
        if(this.checkSameAttr(this.form.materialItems, this.form.materialAttr[1])) return;
        
        // 添加属性数据
        const data = {
          checked: true,
          value: this.form.materialAttr[1]
        };
        this.form.materialItems.push(data);
        // 添加到属性选中数组
        if(data.checked){
          this.form.checkedMaterials.push(data);
        }
        
        // 清空原材质面料属性
        this.form.materialAttr[1] = "";
        
        
        
        // 判断checkedSpecifications和checkedColors中是否存在的数据，有则需要组合没有则添加单条。
        
        // if(this.form.checkedSpecifications.length > 0){
        //   // 清空skuArr
        //   this.form.skuArr = [];
        //   this.form.checkedMaterials.forEach(item => {
        //     this.form.checkedSpecifications.forEach(ob => {
        //       this.form.skuArr.push({
        //         materialName: item.value,
        //         specificationName: ob.value
        //       })
        //     })
        //   })
        // }else{
        //   if(data.checked){
        //     this.form.skuArr.push({
        //       materialName: data.value
        //     })
        //   }
        // }
        
        this.skuCombination(this.form.checkedMaterials, this.form.checkedSpecifications, this.form.checkedColors, "materialName", "specificationName", "color");
        
        
      },
      // 取消或选中材质面料属性项
      materialItemCheckedChange(obj){
        if(obj.checked){
          // 把选中的属性添加到checkedMaterials列表中
          this.form.checkedMaterials.push(obj);
        }else{
          // 把选中checkedMaterials列表中的该项删除
          for(let i= this.form.checkedMaterials.length-1; i >= 0; i--){
            if(this.form.checkedMaterials[i].value == obj.value){
              this.form.checkedMaterials.splice(i, 1);
            }
          }

          // 取消时把该属性项从skuArr中删除
          for(let i= this.form.skuArr.length-1; i >= 0; i--){
            if(this.form.skuArr[i].materialName == obj.value){
              this.form.skuArr.splice(i, 1)
            }
          }
          
        }
        
        this.skuCombination(this.form.checkedMaterials, this.form.checkedSpecifications, this.form.checkedColors, "materialName", "specificationName", "color");
        
        console.log(this.form.checkedMaterials);
      },
      // 删除材质面料属性项 
      materialItemDel(item){
        // 删除该项材质面料属性之前先把skuArr中对应该项的属性全部删除
        // 该项checked为true则需执行删除，为false则不需要执行删除，因为本就没添加进skuArr中
        if(item.checked){
          // 删除该项材质面料属性项在skuArr中对应的所有项
          for(let i= this.form.skuArr.length-1; i >= 0; i--){
            if(this.form.skuArr[i].materialName == item.value){
              this.form.skuArr.splice(i, 1)
            }
          }
          // 删除checkedMaterials列表对应的该项
          for(let i= this.form.checkedMaterials.length-1; i >= 0; i--){
            if(this.form.checkedMaterials[i].value == item.value){
              this.form.checkedMaterials.splice(i, 1);
            }
          }
          
          // checkedMaterials列表为空的时候，checkedSpecifications列表不为空的时候，把不为空的作为单条添加到skuArr中
          // if(this.form.checkedMaterials.length <= 0 && this.form.checkedSpecifications.length > 0){
          //   this.form.checkedSpecifications.forEach(item => {
          //     this.form.skuArr.push({
          //       specificationName: item.value
          //     })
          //   })
          // }
        }
        let index = this.form.materialItems.indexOf(item);
        if(index !== -1){
          this.form.materialItems.splice(index, 1);
        }
        
        this.skuCombination(this.form.checkedMaterials, this.form.checkedSpecifications, this.form.checkedColors, "materialName", "specificationName", "color");
        console.log(this.form.checkedMaterials);
      },
      
      // 添加规格/尺寸属性项 
      addSpecificationItem(){
        // 判断属性是否存在
        if(!this.form.specification[1]) return this.$message.error(`请输入${this.form.specification[0]}属性`);
        
        // 判断是否添加重复属性
        if(this.checkSameAttr(this.form.materialItems, this.form.materialAttr[1])) return;
        
        // 添加属性数据
        const data = {
          checked: true,
          value: this.form.specification[1]
        };
        this.form.specificationItems.push(data);
        
        // 添加到checkedSpecifications属性选中数组
        if(data.checked){
          this.form.checkedSpecifications.push(data);
        }
        
        // 清空规格/尺寸料属性
        this.form.specification[1] = "";
        
        this.skuCombination(this.form.checkedSpecifications, this.form.checkedMaterials, this.form.checkedColors, "specificationName", "materialName", "color");
        
        
      },
      
      // 取消或选规格/尺寸料属性项
      specificationItemCheckedChange(obj){
        if(obj.checked){
          // 把选中的属性添加到checkedSpecifications列表中
          this.form.checkedSpecifications.push(obj);
          // if(this.form.checkedMaterials.length > 0){
          //   // 清空skuArr
          //   this.form.skuArr = [];
          //   this.form.checkedMaterials.forEach(item => {
          //     this.form.checkedSpecifications.forEach(ob => {
          //       this.form.skuArr.push({
          //         materialName: item.value,
          //         specificationName: ob.value
          //       })
          //     })
          //   })
          // }else{
          //   this.form.skuArr.push({
          //     specificationName: obj.value
          //   })
          // }
          
        }else{
          // 把选中checkedSpecifications列表中的该项删除
          for(let i= this.form.checkedSpecifications.length-1; i >= 0; i--){
            if(this.form.checkedSpecifications[i].value == obj.value){
              this.form.checkedSpecifications.splice(i, 1);
            }
          }
        
          // 取消时把该属性项从skuArr中删除
          for(let i= this.form.skuArr.length-1; i >= 0; i--){
            if(this.form.skuArr[i].specificationName == obj.value){
              this.form.skuArr.splice(i, 1)
            }
          }
          
          // checkedSpecifications列表为空的时候，checkedMaterials列表不为空的时候，把不为空的作为单条添加到skuArr中
          // if(this.form.checkedMaterials.length > 0 && this.form.checkedSpecifications.length <= 0){
          //   this.form.checkedMaterials.forEach(item => {
          //     this.form.skuArr.push({
          //       materialName: item.value
          //     })
          //   })
          // }
          
        }
        
        this.skuCombination(this.form.checkedSpecifications, this.form.checkedMaterials, this.form.checkedColors, "specificationName", "materialName", "color");
        
        console.log(this.form.checkedSpecifications);
      },
      
      // 删除规格/尺寸属性项
      specificationItemDel(item){
        // 删除该项材质面料属性之前先把skuArr中对应该项的属性全部删除
        // 该项checked为true则需执行删除，为false则不需要执行删除，因为本就没添加进skuArr中
        if(item.checked){
          // 删除该项材质面料属性项在skuArr中对应的所有项
          for(let i= this.form.skuArr.length-1; i >= 0; i--){
            if(this.form.skuArr[i].specificationName == item.value){
              this.form.skuArr.splice(i, 1)
            }
          }
          // 删除checkedSpecifications列表对应的该项
          for(let i= this.form.checkedSpecifications.length-1; i >= 0; i--){
            if(this.form.checkedSpecifications[i].value == item.value){
              this.form.checkedSpecifications.splice(i, 1);
            }
          }
          
          // checkedSpecifications列表为空的时候，checkedMaterials列表不为空的时候，把不为空的作为单条添加到skuArr中
          // if(this.form.checkedMaterials.length > 0 && this.form.checkedSpecifications.length <= 0){
          //   this.form.checkedMaterials.forEach(item => {
          //     this.form.skuArr.push({
          //       materialName: item.value
          //     })
          //   })
          // }
        }

        let index = this.form.specificationItems.indexOf(item);
        if(index !== -1){
          this.form.specificationItems.splice(index, 1);
        }
        
        this.skuCombination(this.form.checkedSpecifications, this.form.checkedMaterials, this.form.checkedColors, "specificationName", "materialName", "color");
        console.log(this.form.checkedSpecifications);
        
      },
      // 检测相同属性，防止重复添加
      checkSameAttr(obj, val){
        for(var key in obj){
          if(obj[key].value == val){
            this.$message.error("属性已存在；请勿添加重复属性！"); 
            return true;
          }
        }
      },
      // 选择颜色
      selectColors(obj){
        obj.checked = !obj.checked;
        if(obj.checked){
          this.form.checkedColors.push(obj);
        }else{
          // 取消选中的时候删除checkedColors列表中对应的数据
          let index = this.form.checkedColors.indexOf(obj);
          if(index !== -1){
            this.form.checkedColors.splice(index, 1);
          }
        }
        console.log(this.form.checkedColors)
        // 分别跟checkedMaterials和checkedSpecifications属性搭配
        this.skuCombination(this.form.checkedColors, this.form.checkedMaterials, this.form.checkedSpecifications, "color", "materialName", "specificationName");
        
      },
      // sku组合判断函数  materialName specificationName color
      skuCombination(currObj, attrObjA, attrObjB, currAttrName, objAAttrName, objBAttrName){
        // 清空skuArr
        this.form.skuArr = [];
        if(attrObjA.length > 0 && attrObjB.length > 0){
          if(currObj.length > 0){
            // 重组sku属性
            currObj.forEach(currItem => {
              attrObjA.forEach(objItemA => {
                attrObjB.forEach(objItemB => {
                  const obj = {
                    salePrice: 0,
                    stock: 0,
                    goodsMarque: "",
                    upload: {
                      url: `${Url}/ossupload/uploadImg.do`
                    }
                  };
                  obj[currAttrName] = currItem.value;
                  obj[objAAttrName] = objItemA.value;
                  obj[objBAttrName] = objItemB.value;
                  this.form.skuArr.push(obj);
                })
              })
            })
            
          }else{
            // 重组sku属性
            attrObjA.forEach(objItemA => {
              attrObjB.forEach(objItemB => {
                const obj = {
                  salePrice: 0,
                  stock: 0,
                  goodsMarque: "",
                  upload: {
                    url: `${Url}/ossupload/uploadImg.do`
                  }
                };
                obj[objAAttrName] = objItemA.value;
                obj[objBAttrName] = objItemB.value;
                this.form.skuArr.push(obj);
              })
            })
          }
          console.log(this.form.skuArr)
        }
        
        if(attrObjA.length > 0 && attrObjB.length <= 0){
          if(currObj.length > 0){
            // 重组sku属性
            currObj.forEach(currItem => {
              attrObjA.forEach(objItemA => {
                const obj = {
                  salePrice: 0,
                  stock: 0,
                  goodsMarque: "",
                  upload: {
                    url: `${Url}/ossupload/uploadImg.do`
                  }
                };
                obj[currAttrName] = currItem.value;
                obj[objAAttrName] = objItemA.value;
                this.form.skuArr.push(obj);
              })
            })
            
          }else{
            // 重组sku属性
            attrObjA.forEach(objItemA => {
              const obj = {
                salePrice: 0,
                stock: 0,
                goodsMarque: "",
                upload: {
                  url: `${Url}/ossupload/uploadImg.do`
                }
              };
              obj[objAAttrName] = objItemA.value;
              this.form.skuArr.push(obj);
            })
          }
          console.log(this.form.skuArr)
        }
        
        if(attrObjA.length <= 0 && attrObjB.length > 0){
          if(currObj.length > 0){
            // 重组sku属性
            currObj.forEach(currItem => {
              attrObjB.forEach(objItemB => {
                const obj = {
                  salePrice: 0,
                  stock: 0,
                  goodsMarque: "",
                  upload: {
                    url: `${Url}/ossupload/uploadImg.do`
                  }
                };
                obj[currAttrName] = currItem.value;
                obj[objBAttrName] = objItemB.value;
                this.form.skuArr.push(obj);
              })
            })
            
          }else{
            // 重组sku属性
            attrObjB.forEach(objItemB => {
              const obj = {
                salePrice: 0,
                stock: 0,
                goodsMarque: "",
                upload: {
                  url: `${Url}/ossupload/uploadImg.do`
                }
              };
              obj[objBAttrName] = objItemB.value;
              this.form.skuArr.push(obj);
            })
          }
          console.log(this.form.skuArr)
        }
        
        if(attrObjA.length <= 0 && attrObjB.length <= 0){
          if(currObj.length > 0){
            // 重组sku属性
            currObj.forEach(currItem => {
              const obj = {
                salePrice: 0,
                stock: 0,
                goodsMarque: "",
                upload: {
                  url: `${Url}/ossupload/uploadImg.do`
                }
              };
              obj[currAttrName] = currItem.value;
              this.form.skuArr.push(obj);
            })
          }else{
            // 清空skuArr
            this.form.skuArr = [];
          }
          console.log(this.form.skuArr)
        }
      },
      
      submit (form) {
        console.log(this.$refs.text.value)
        this.$refs[form].validate((valid) => {
          if (valid) {
            // alert('submit!');
            console.log(this.form)
            console.log(this.$refs[form])
            const params = {
              token: this.token,
              goods: {
                goodsName: "测试商品",
                goodsType: "臻选家具",
                brandName: "臻选",
                styleType: "52",
                secondStyleType: "50",
                firstLabelId: "2",
                labelId: "1",
                thirdLabelId: "2",
                operateDiscount: "0.34",
                memberDiscount: "0.35",
                distributionDiscount: "0.4",
                service_introduce: "测试服务介绍",
                sort: "0",
                goods_introduce: "测试商品详情"
              },
              one:["进口实木+高回弹海绵+优质超纤皮+碳素钢框架脚"],
              two:["1170*940*780mm(单人沙发"],
              three:["#f469b1"],
              skuPrice:["100"],
              skuSor:["1000"],
              skuMarque:["cs"],
              skuImgUrl:["https://e-home.oss-cn-shenzhen.aliyuncs.com/file/68b8162f-7bb5-49f0-bdf3-da745cc4e199.jpg"]
            }
            console.log(params);
            this.$api.commodityAdd(params).then(res => {
              console.log(res);
            }).catch(err => console.error(err))
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      cancel(){
        
      }
      // editorOption里是放图片上传配置参数用的，例如：
      // action:  '/api/product/richtext_img_upload.do',  // 必填参数 图片上传地址
      // methods: 'post',  // 必填参数 图片上传方式
      // token: '',  // 可选参数 如果需要token验证，假设你的token有存放在sessionStorage
      // name: 'upload_file',  // 必填参数 文件的参数名
      // size: 500,  // 可选参数   图片大小，单位为Kb, 1M = 1024Kb
      // accept: 'multipart/form-data, image/png, image/gif, image/jpeg, image/bmp, image/x-icon,image/jpg'  // 可选 可上传的图片格式
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "@/assets/scss/userCenterCommon";
  @import "@/view/userCenter/addCommodity/addCommodity";
</style>
